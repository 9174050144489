<footer class="bg-light text-center text-lg-start">
  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
   <p>Készítette: Magyar István © {{year}} Az adatok tájékozató jellegűek!</p>
   <p>Adatok forrása:</p>
   <p><a class="text-dark" target="_blank" href="https://www.mongodb.com/developer/article/johns-hopkins-university-covid-19-rest-api/">A Free REST API for Johns Hopkins University COVID-19 dataset</a></p>
   <p><a class="text-dark" target="_blank" href="https://about-corona.net/">corona-api.com</a></p>
   <p><a class="text-dark" target="_blank" href="https://mmediagroup.fr/covid-19">mediagroup.fr</a></p>
  </div>
  <!-- Copyright -->
</footer>
