<div class="container">

<h1>Napi adatok Magyarországon</h1>

<div class="row cards">

  <app-card *ngFor="let card of cards" class="col-md-4 mx-auto w-auto d-flex align-items-stretch" [title]="card.title" [content]="card.content"></app-card>

</div>

<app-chart></app-chart>

</div>
