<div class="card text-white card bg-dark text-center mb-3">
  <div class="card-header">{{title}}</div>
  <div class="card-body">
    <h1 class="card-title">{{content}}</h1>
  </div>
  <div class="card-footer">
    {{footer}}
  </div>
</div>

